import Logo from "../assets/text-logo.png";
import { Link } from "react-router-dom";

export default function NavBar() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <header className="taxback-nav">
      <div className="nav" id="navBar">
        <h1 onClick={scrollToTop}>
          <img src={Logo} alt="TaxBack" />
        </h1>
        <ul className="nav-list">
          <li>
            <Link to="http://pf.kakao.com/_WxkTxkxj" target="_blank">
              문의하기
            </Link>
          </li>
          <li>
            <Link to="https://leonetax.kr/" target="_blank">
              세무법인 리원
            </Link>
          </li>
          <li>
            <Link to="https://www.prior.co.kr/" target="_blank">
              세무법인 프라이어
            </Link>
          </li>
          <li>
            <Link to="https://total.tax-back.kr/" target="_blank">
              환급금 무료 조회
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
}
