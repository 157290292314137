import textLogoW from "../assets/text-w-logo.png";

export default function Footer() {
  return (
    <div className="footer" id="footerTb">
      <div className="footer-inner">
        <img src={textLogoW} className="footer-logo" alt="" />
        <div className="footer-info">
          <p>서울특별시 강남구 테헤란로 423 (현대타워 5층, 7층)</p>
          <p>
            <span>문의전화</span>양도세 1533-5695 / 법인세,종합소득세
            02-6204-0351,0355 (평일 09:30 ~ 18:00 / 주말,공휴일 휴무)
          </p>
          <p>
            <span>Email</span>양도세 priortax1020@naver.com / 법인세,종합소득세
            prior@prior.co.kr
          </p>
          <p>
            <span>홈페이지</span>
            <a href="https://www.prior.co.kr/" target="_blank">
              prior.co.kr
            </a>
            &nbsp;|&nbsp;
            <a href="https://leonetax.kr/" target="_blank">
              leonetax.kr
            </a>
          </p>
        </div>
        <div className="comp-info">
          <p>
            세무법인 리원 | 세금 신고 및 세무 기장 | 대표자 : 김태현, 황상태
            세무사 <br />
            세무법인 프라이어 | 세금 신고 및 세무 기장 | 대표자: 김현성, 장서환,
            진대근 세무사 <br />
          </p>
          <p className="comp-info-right">©2022 TaxBack. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
}
